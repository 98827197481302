import http from '../http-common';
import { customizeError, getResponseData } from '@utils/_function';

export default class BaseService {
  constructor(path) {
    this.path = path;
  }

  filterBy(identifiant, queries) {
    return http
      .post(`${this.path}/external/${identifiant}`, queries)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getByIdentifiant(identifiant) {
    return http
      .get(`${this.path}/external/${identifiant}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  findById(id) {
    return http
      .get(`${this.path}/${id}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getByEnterpriseId(enterpriseIdentifiant) {
    return http.get(`${this.path}/enterprise/${enterpriseIdentifiant}`);
  }

  getResponseData = (response) => getResponseData(response)
}
