import React from 'react';
import { Spinner } from "@assets/IconeSvg";

const LoadingPage = ({settings, style}) => {
    return (
        <div className="position_absolute loading" style={style}>
            {
              settings?.customColor?.rgba1 ? Spinner(settings?.customColor?.rgba1) : Spinner("#eee")
            }
          </div>
    )
}

export default LoadingPage;