import React, { Suspense, lazy, useState, useEffect, useCallback } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import LoadingPage from "@components/Loading/LoadingPage";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GENERAL_DOMAINS } from "../server/constant";
import { getNormalIdentifiant } from "@utils/_function";
import settingService from "@services/setting.service";
import { connect } from "react-redux";
import ROUTES from "./routes";
import { menuList } from "@pages/config/menu.config";
const Layout = lazy(() => import("@pages/layouts/Layout"));
const PageNotFound = lazy(() => import("@pages/errorPages/pageErreur404"));

function App(props) {
  const [isDomain, setIsDomain] = useState(false);
  const [enterpriseIdentifiant, setEnterpriseIdentifiant] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const getIdentifiantByDomain = useCallback(async () => {
    // console.log('Loading google domain identifiant');
    dispatch({ type: "SET_ISLOADING_IDENTIFIANT", payload: true });
    try {
      const domain = window.location.hostname;
      const _enterpriseIdentifiant =
        await settingService.getEnterpriseIdentifiantByDomain(domain);
      setEnterpriseIdentifiant(_enterpriseIdentifiant);
    } catch (err) {
      console.log("error getting enterpriseIdentifiant: ", err);
    } finally {
      dispatch({ type: "SET_ISLOADING_IDENTIFIANT", payload: false });
    }
  });

  useEffect(() => {
    localStorage.setItem("source", "");
    const domain = window.location.hostname;
    const isDomain = !GENERAL_DOMAINS.includes(domain);
    setIsDomain(isDomain);
    if (isDomain) {
      getIdentifiantByDomain(domain);
    } else {
      const _enterpriseIdentifiant = getNormalIdentifiant(location.pathname);
      setEnterpriseIdentifiant(_enterpriseIdentifiant);
    }
    dispatch({ type: "SET_IS_DOMAIN", payload: isDomain });
    /*eslint no-unused-vars:off*/
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET_ENTERPRISE_IDENTIFIANT",
      payload: enterpriseIdentifiant
    });
  }, [enterpriseIdentifiant]);

  const isHiddenComponent = (path, Component) => () => {
    let listMenu = menuList;
    let settingsEnterprise = JSON.parse(
      localStorage.getItem("settingsEnterprise")
    );
    if (settingsEnterprise.disableInformations)
      listMenu = listMenu.filter((o) => o.id !== "information");
    if (settingsEnterprise.disableTeam)
      listMenu = listMenu.filter((o) => o.id !== "equipe");
    if (settingsEnterprise.disableFlexibility)
      listMenu = listMenu.filter(
        (o) => o.id !== "flexibilite-et-environnement"
      );
    const currentUrl = location.pathname.split("/");
    let route = currentUrl[currentUrl.length - 1];
    if (!listMenu.find((el) => el.id === route)) {
      route = listMenu[0].id;
    }
    let defaultPath = isDomain
      ? `/${route}`
      : `/${enterpriseIdentifiant}/${route}`;
    // eslint-disable-next-line
    return path === "/" ? (
      settingsEnterprise.disableInformations ? (
        <Redirect to={defaultPath} />
      ) : (
        <Component />
      )
    ) : path === "/equipe" || path === "/equipe/:temoignage" ? (
      settingsEnterprise.disableTeam ? (
        <Redirect to={defaultPath} />
      ) : (
        <Component />
      )
    ) : path === "/flexibilite-et-environnement" ? (
      settingsEnterprise.disableFlexibility ? (
        <Redirect to={defaultPath} />
      ) : (
        <Component />
      )
    ) : (
      <Component />
    );
  };

  if (!enterpriseIdentifiant) {
    return <LoadingPage settings={props.settings} />;
  }

  return (
    <>
      <div>
        <Suspense fallback={<LoadingPage settings={props.settings} />}>
          <Switch>
            {ROUTES.map(({ path, component }) => (
              <RouteWrapper
                exact
                path={`${!isDomain ? "/:enterpriseIdentifiant" : ""}${path}`}
                component={isHiddenComponent(path, component)}
                layout={Layout}
              />
            ))}
            <Route component={PageNotFound} />
          </Switch>
          <NotificationContainer />
        </Suspense>
      </div>
    </>
  );
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
const mapStateToProps = (state) => {
  return { settings: state.settings };
};

export default connect(mapStateToProps)(App);
