// const API_URL = 'https://app.altee.com/api/';
// const FRONT_URL = 'http://51.161.80.108:4050/';
const API_URL = process.env.API_URL;
const FRONT_URL = process.env.FRONT_URL;

const FRONT_JSON_LD_BALISE = '<script type="application/ld+json"></script>';

const GENERAL_DOMAINS = [
  "career.altee.com",
  "www.career.altee.com",
  "51.91.146.243:9001",
  "51.91.146.243:9005",
  "51.161.81.11:9001",
  "51.91.146.243",
  "51.161.81.11",
  "localhost:9001",
  "localhost"
]

module.exports = {
  FRONT_URL,
  API_URL,
  GENERAL_DOMAINS,
  FRONT_JSON_LD_BALISE
};