import axios from 'axios';
// import { Server } from "miragejs";
// import { routesServerTest } from '@utils/testing_api/serverTest'

// Using temporary API to data
//new Server(routesServerTest)  

export default axios.create({
  baseURL: process.env.REACT_APP_SERVER_URI,
});
