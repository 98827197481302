import { lazy }  from 'react'

const Informations = lazy(() => import('@pages/container/informations'));
const FlexibilityAndEnvironment = lazy(() => import('@pages/container/flexibiliteEtEnvironnement'));
const Equipe = lazy(() => import('@pages/container/equipe'));
const Emplois = lazy(() => import('@pages/container/Emplois/emplois'));
const DetailsEmplois = lazy(() => import('@pages/container/Emplois/details'));
const ApplyEmplois = lazy(() => import('@pages/container/Emplois/apply'));

const ROUTES = [
  {
    path: "/",
    component: Informations
  },
  {
    path: "/flexibilite-et-environnement",
    component: FlexibilityAndEnvironment
  },
  {
    path: "/equipe",
    component: Equipe
  },
  {
    path: "/equipe/:temoignage",
    component: Equipe
  },
  {
    path: "/emplois",
    component: Emplois
  },
  {
    path: "/emplois/details/:id",
    component: DetailsEmplois
  },
  {
    path: "/emplois/apply/:paramJob",
    component: ApplyEmplois
  }
]

export default ROUTES;