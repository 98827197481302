// List of header menu
// id = url
// name = text of the menu
export const menuList = [
  {
    id: 'information',   
    name: "Informations"
  }, 
  {
    id: 'equipe',
    name: "Équipe"
  },
  {
    id: 'flexibilite-et-environnement',
    name: "Flexibilité"
  },
  {
    id: 'emplois',
    name: "Emplois"
  }
];