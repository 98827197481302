//Check api response data
/*eslint array-callback-return:off */
export const getResponseData = (response, single = false) => {
  if (response?.status === 200 && response.data?.data) {
    if (single && response.data.data?.[0]) return response.data.data[0];
    else return response.data.data;
  } else {
    return Promise.reject(customizeError(response.data));
  }
};

export const customizeError = (err) => {
  let err_message = 'Erreur inconnu!';
  if (err) {
    if (err.error) {
      err = err.error;
    }
    if (err.message) {
      err_message = err.message;
    } else if (err.msg) {
      err_message = err.msg;
    }
  }
  let errorCustomized = {
    success: false,
    error: err,
    status: false,
    message: err_message,
  };
  return errorCustomized;
};

export const fixHttps = (url) => {
  if (!url) return '#';
  var pattern = /^((http|https|ftp):\/\/)/;

  if (!pattern.test(url)) {
    url = "https://" + url;
  }
  return url;
}

// Get image by url
export const _img = (imageName) => {

  // Temporary url to get image
  const SERVER_IMAGE = "/img";
  return `${SERVER_IMAGE}/${imageName}`;
}

export const getDefinedListByArrayIds = (definedList, arrayIds) => {
  if (!Array.isArray(definedList) || !Array.isArray(arrayIds))
    return [];
  return definedList.filter(el => arrayIds.includes(el._id));
}
export const getDefinedListByIdsOrArrayIds = (definedList, arrayIds) => {
  let newArray = []
  if (!Array.isArray(arrayIds) && arrayIds) {
    newArray = JSON.parse(arrayIds)
  } else {
    newArray = arrayIds
  }
  if (!Array.isArray(definedList) || !newArray?.length)
    return [];
  return definedList.filter(el => newArray.includes(el._id));
}

export const getArrayOfIntfromStringArray = (string) => {
  let newArray = [];
  newArray = Array.from(string);
  return newArray.filter(e => { if (!isNaN(parseInt(e))) return e });
}

export const getLabelByKeyFromConstantArray = (key, ConstantArrayId) => {
  if (!key && !Array.isArray(ConstantArrayId)) return [];
  return ConstantArrayId.filter(el => el._id === key).map(e => e.label)[0];
}

export const addStarsIfRequired = (question) => question?.required ? " *" : "";

const objectProperties = (obj, props) => {
  if (typeof props === "string") {
    props = [props];
  }
  if (!Array.isArray(props)) {
    props = Object.keys(obj);
  }
  return props;
}

export const isSomePropertiesNotNullable = (obj = {}, props) => objectProperties(obj, props).some(prop => obj[prop]);

export const isAllPropertiesNullable = (obj = {}, props) => objectProperties(obj, props).every(prop => !obj[prop]);

function isPreviousButton(element) {
  return element?.firstElementChild && element?.firstElementChild?.className?.includes("years-previous");
}

function isUpCummingButton(element) {
  return element?.firstElementChild && element?.firstElementChild?.className?.includes("years-upcoming");
}

function textContent(element) {
  return +element?.innerText?.replace("✓\n", "") || "";
}

function sortYear(a, b) {
  if (isPreviousButton(a) || isUpCummingButton(b)) {
    return -1;
  }
  if (isUpCummingButton(a) || isPreviousButton(b)) {
    return 1;
  }

  return textContent(a) - textContent(b);
}

export function reverseDropwdownYear() {
  setTimeout(() => {
    const yearDropdown = this.firstElementChild;
    if (yearDropdown?.childNodes?.length) {
      let childElements = Array.from(yearDropdown.childNodes);
      childElements.sort(sortYear);
      yearDropdown.append(...childElements);
    }
    if (document.querySelector(".react-datepicker__year-dropdown")) {
      let top = document.querySelector('.react-datepicker__year-option--selected_year').offsetTop
      document.querySelector('.react-datepicker__year-dropdown').scroll(0, (top - 5));
    }
  }, 0); //<- permet d'attendre que le dropdown s'affiche
}

export const getNormalIdentifiant = (param) => param.split('/')[1];

export function diacriticSensitiveRegex(string = '') {
  return string.replaceAll(/ae/g, '[ae,æ]')
    .replaceAll(/oe/g, '[oe,œ]')
    .replaceAll(/AE/g, '[AE,Æ]')
    .replaceAll(/OE/g, '[OE,Œ]')
    .replaceAll(/a/g, '[a,á,à,â,ä,å,ã]')
    .replaceAll(/c/g, '[c,ç]')
    .replaceAll(/e/g, '[e,é,è,ê,ë]')
    .replaceAll(/i/g, '[i,í,ì,î,ï]')
    .replaceAll(/n/g, '[n,ñ]')
    .replaceAll(/o/g, '[o,ó,ò,ô,ö,õ,ø]')
    .replaceAll(/s/g, '[s,š]')
    .replaceAll(/u/g, '[u,ú,ù,û,ü]')
    .replaceAll(/y/g, '[y,ý,ÿ]')
    .replaceAll(/z/g, '[z,ž]')
    .replaceAll(/A/g, '[A,Á,À,Â,Ä,Å,Ã]')
    .replaceAll(/C/g, '[C,Ç]')
    .replaceAll(/E/g, '[E,É,È,Ê,Ë]')
    .replaceAll(/I/g, '[I,Í,Ì,Î,Ï]')
    .replaceAll(/N/g, '[N,Ñ]')
    .replaceAll(/O/g, '[O,Ó,Ò,Ô,Ö,Õ,Ø]')
    .replaceAll(/S/g, '[S,Š]')
    .replaceAll(/U/g, '[U,Ú,Ù,Û,Ü]')
    .replaceAll(/Y/g, '[Y,Ý,Ÿ]')
    .replaceAll(/Z/g, '[Z,Ž]')
    .replaceAll(/'/g, "[',`]")
}