import { createStore } from '@reduxjs/toolkit'

const initialState = {
  enterprise: {},
  isDomain: false,
  enterpriseIdentifiant: "",
  isLoadingIdentifiant: false,
  settings: {},
  //STANDARD_NOTIFICATION
  standardNotification: [],
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ENTERPRISE":
      return Object.assign({}, state, {
        enterprise: action.payload,
      });
    case "SET_IS_DOMAIN":
      return Object.assign({}, state, {
        isDomain: action.payload,
      });
    case "SET_ENTERPRISE_IDENTIFIANT":
      return Object.assign({}, state, {
        enterpriseIdentifiant: action.payload,
      });
    case "SET_SETTING":
      return Object.assign({}, state, {
        settings: action.payload,
      });
    case "SET_ISLOADING_IDENTIFIANT":
      return Object.assign({}, state, {
        isLoadingIdentifiant: action.payload
      })
    case "STANDARD_NOTIFICATION":
        return Object.assign({}, state, {
          standardNotification: state.standardNotification.concat(action.payload),
        });
    default : return state
  }
}

const store = createStore(reducer);

export default store;