import BaseService from '@services/base.service';
import http from '../http-common';
import { customizeError, getResponseData } from '@utils/_function';

class SettingService extends BaseService {
  constructor() {
    super('settings');
  }

  getOneByJobId(jobId) {
    return http
      .get(`${this.path}/job/${jobId}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getEnterpriseIdentifiantByDomain(domain) {
      return http
      .get(`${this.path}/external/getEnterpriseIdentifiantByDomain/${domain}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

}

export default new SettingService();
