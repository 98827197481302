import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next  } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';

const settingsEnterprise = JSON.parse(localStorage.getItem('settingsEnterprise'));

i18n
  .use(resourcesToBackend(async (language, namespace, callback) => {
    if(namespace?.indexOf(".") > 0){
      namespace = namespace.replaceAll(".", "/");
    }
    await import(`../public/locales/${language}/${namespace}.json`)
      .then((resources) => {
        callback(null, resources);
      })
      .catch((error) => {
        callback(error, null);
      });
  }))
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: settingsEnterprise?.valueLanguage?.initial || "fr",
    fallbackLng: "fr",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
      wait: false,
    },
  });

export default i18n;
