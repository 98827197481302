import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '@assets/css/index.css';
import '@assets/css/tailwind/output.css';
import store from '@store/index';
import { Provider } from 'react-redux';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from '@pages/errorPages/pageErrorBoundary';

// require("@utils/_prototype");

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,

  document.getElementById('root'),
);

serviceWorker.unregister();
